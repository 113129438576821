import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/Movilidad',
    name: 'Movilidad',
    component: () => import(/* webpackChunkName: "Movilidad" */ '../views/Movilidad.vue')
  },
  {
    path: '/IMEI/:imei',
    name: 'IMEI',
    component: () => import(/* webpackChunkName: "IMEI" */ '../views/IMEI.vue')
  },
  {
    path: '/Recargas',
    name: 'Recargas',
    component: () => import(/* webpackChunkName: "Recargas" */ '../views/Recargas.vue')
  },
  {
    path: '/Recargas2/:numero/:plan/:metodo',
    name: 'Recargas2',
    component: () => import(/* webpackChunkName: "Recargas2" */ '../views/Recargas2.vue')
  },
  {
    path: '/AgregarUsrs/:imei/:coordenadas?',
    name: 'AgregarUsrs',
    component: () => import(/* webpackChunkName: "AgregarUsrs" */ '../views/AgregarUsrs.vue')
  },
  {
    path: '/Internet',
    name: 'Internet',
    component: () => import(/* webpackChunkName: "Internet" */ '../views/Internet.vue')
  },
  {
    path: '/Perfil',
    name: 'Perfil',
    component: () => import(/* webpackChunkName: "Perfil" */ '../views/Perfil.vue')
  },
  {
    path: '/Usuarios',
    name: 'Usuarios',
    component: () => import(/* webpackChunkName: "Usuarios" */ '../views/Usuarios.vue')
  },
  {
    path: '/OlvidaContra',
    name: 'OlvidaContra',
    component: () => import(/* webpackChunkName: "OlvidaContra" */ '../views/OlvidaContra.vue')
  },
  {
    path: '/VerificaCodigo/:email',
    name: 'VerificaCodigo',
    component: () => import(/* webpackChunkName: "VerificaCodigo" */ '../views/VerificaCodigo.vue')
  },
  {
    path: '/Planes',
    name: 'Planes',
    component: () => import(/* webpackChunkName: "Planes" */ '../views/Planes.vue')
  },
  {
    path: '/Promociones',
    name: 'Promociones',
    component: () => import(/* webpackChunkName: "Promociones" */ '../views/Promociones.vue')
  },
  {
    path: '/Cambaceadores',
    name: 'Cambaceadores',
    component: () => import(/* webpackChunkName: "Cambaceadores" */ '../views/Cambaceadores.vue')
  },
  {
    path: '/Recibos',
    name: 'Recibos',
    component: () => import(/* webpackChunkName: "Recibos" */ '../views/Recibos.vue')
  },
  {
    path: '/Pagos/:numero/:plan/:metodo?',
    name: 'Pagos',
    component: () => import(/* webpackChunkName: "Pagos" */ '../views/Pagos.vue')
  },
  {
    path: '/PagosActivacion/:body/:body3/:body2/:body4',
    name: 'Pagos Activacion',
    component: () => import(/* webpackChunkName: "PagosActivacion" */ '../views/PagosActivacion.vue')
  },
  {
    path: '/Vendedores',
    name: 'Vendedores',
    component: () => import(/* webpackChunkName: "Vendedores" */ '../views/Vendedores.vue')
  },
  {
    path: '/Transacciones',
    name: 'Ventas',
    component: () => import(/* webpackChunkName: "Transacciones" */ '../views/Transacciones.vue')
  },
]

// router.onError(error=>{
//   if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
//     window.location.reload()
//   }
// })

// window.onerror = function(mMsg, mSource, mLineNo, mColNo, error){
//   // mMsg = Char. Error Msg: i.e. "Uncaugh SyntaxError: Unexpected token '<'"
//   // mSource = Char. i.e. 'https://yoursite.com/js/chunk-431587f6.ff603bf5.js'
//   // mLineNo = Numeric. Line no
//   // mColNo = Numeric. Col no
//   // error = Object. Error object        
//   if (mMsg.toLowerCase().indexOf("unexpected token '<'") >-1){
//       // this happens when a new update gets applies but my router.js file hasn't been pulled down for whatever reason. A page refresh fixes it. 
//       // mSource = 
//       if (navigator.onLine){
//            window.location.reload();
//       }
//   }

// }; // window.onerror

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
