<template>
  <div class="home">
    <login-vue></login-vue>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginVue from './Login.vue'
export default {
  name: 'Home',
  components: {
    LoginVue
  }
}
</script>
