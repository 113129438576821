<template>
  <v-app id="inspire">

    <!-- BARRA SUPERIOR -->
    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-app-bar-nav-icon v-if="!muestra" @click.stop="menu=!menu"></v-app-bar-nav-icon>
      <v-app-bar-title v-if="showTitle()">{{ this.$route.name }}</v-app-bar-title>
    </v-app-bar>

    <v-main>
      <v-navigation-drawer
        v-if="!muestra"
        v-model="menu"
        absolute
        temporary
      >
        <v-list
        nav
        dense>
          <v-list-item-group active-class="primary white--text">
            <v-list-item to="/Movilidad">Movilidad</v-list-item>
            <v-list-item to="/Internet">Internet</v-list-item>
            <v-list-item to="/Recargas">Recargas</v-list-item>
            <v-list-item to="/Usuarios" v-if="tipo">Usuarios</v-list-item>
            <v-list-item to="/Vendedores" v-if="tipo">Vendedores</v-list-item>
            <v-list-item to="/Cambaceadores" v-if="tipo">Cambaceadores</v-list-item>
            <v-list-item to="/Planes" v-if="tipo">Planes</v-list-item>
            <v-list-item to="/Promociones" v-if="tipo">Promociones</v-list-item>
            <v-list-item to="/Recibos">Recibos</v-list-item>
            <v-list-item to="/Transacciones">Ventas</v-list-item>
            <v-list-item to="/Perfil">Perfil</v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <!-- <v-card id="pantallas" class="elevation-3 " height="100%"> -->
        <v-container fluid class="mx-0">
          <v-slide-y-transition mode="out-in">
            <router-view @muestra="muestra = $event" />
          </v-slide-y-transition>
        </v-container>
      <!-- </v-card> -->
    </v-main>

  </v-app>

</template>

<script>
  export default {
    data: () => ({
      muestra: true,
      menu: false,
      tipo: false
    }),

    created () {
    },
    methods:{
      showTitle(){
        return !(this.$route.name === 'Home' || this.$route.name === 'AgregarUsrs' || this.$route.name === 'Login' || this.$route.name === 'OlvidaContra' || this.$route.name === 'VerificaCodigo')
      }
    },
    updated(){
      if (localStorage.getItem('tipo')==='ADMINISTRADOR') {
        this.tipo = true
      }else{
        this.tipo = false
      }
    }
  };
</script>

